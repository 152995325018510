import React from "react";
import "../layout.css";
import Logo from "../images/RiTechLogo.svg";
import styled from "styled-components";

const ComingSoon = styled.div`
    display: flex;
    justify-content: center;
    flex-direction: column;
    min-height: 100vh;
    align-items: center;
    max-width: 80vw;
    margin: 0 auto;

    .lg-p-txt {
        font-size: 2rem;
        text-align: center;
        line-height: 2.5rem;
        color: #494d50;
    }
    .med-p-txt {
        font-size: 1.4rem;
        text-align: center;
        line-height: 2.5rem;
        color: #8e979e;
    }

    span {
        color: #4880c2;
    }
    .py-lg {
        padding: 3rem 0;
    }
`;

export default () => (
    <ComingSoon>
        <img src={Logo} alt="Ri-Tech Logo" style={{ width: "350px" }} />
        <p className="lg-p-txt py-lg">
            Ri-Tech Drug & DNA Testing is a leader in promoting and helping
            companies establish a “drug free workplace.”
        </p>
        <p className="med-p-txt">
            We are proud to serve as El Paso’s premiere Drug & DNA testing
            facility.
        </p>
        <h3>
            #ELPASO<span>STRONG</span>
        </h3>
    </ComingSoon>
);
